exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asset-classes-tsx": () => import("./../../../src/pages/asset-classes.tsx" /* webpackChunkName: "component---src-pages-asset-classes-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-disclaimers-tsx": () => import("./../../../src/pages/disclaimers.tsx" /* webpackChunkName: "component---src-pages-disclaimers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leste-funds-tsx": () => import("./../../../src/pages/leste-funds.tsx" /* webpackChunkName: "component---src-pages-leste-funds-tsx" */),
  "component---src-pages-our-approach-tsx": () => import("./../../../src/pages/our-approach.tsx" /* webpackChunkName: "component---src-pages-our-approach-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pt"],"defaultLanguage":"pt","siteUrl":"http://localhost:8000/","redirect":true,"generateDefaultLanguagePage":true,"pages":[{"matchPath":"/cookies","languages":["pt"]},{"matchPath":"/leste-funds","languages":["pt"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2L5KVV61KB"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leste Site","short_name":"Leste Site","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"./static/favicon.png","icons":[{"src":"./static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc53d7684e62f918f060bd9544088708"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
